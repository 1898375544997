@tailwind base;
@tailwind components;
@tailwind utilities;






body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}





/** Scrollbar **/
:root {
  --primary: rgba(0, 0, 0, 0);
  --secondary: gray;
}



/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}



/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.6rem;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 0.5rem;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 0.5rem;
  border: 0.2rem solid var(--primary);
}